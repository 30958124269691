export const environment = {
  production: true,
  stsServer: 'https://identity-uat.carscomm.com.au',
  thisUrl: 'https://client-uat.carscomm.com.au',
  userAccountApiGatewayUrl: 'https://uaapi-uat.carscomm.com.au',
  memberCarApiGatewayUrl: 'https://memcarapi-uat.carscomm.com.au',
  searchCarApiGatewayUrl: 'https://searchcarapi-uat.carscomm.com.au',
  regoDataApiGatewayUrl: 'https://regodataapi-uat.carscomm.com.au',
  paymentApiGatewayUrl: 'https://paymentapi-uat.carscomm.com.au',
  applicationInsightsInstrumentationKey: 'df0dcaf7-e988-4163-ab49-d8494c1c6ab3',
  memberMesssageApiGatewayUrl: 'https://memmsgapi-uat.carscomm.com.au',
  accidentManagementJobTypeId: '5dec9013435d3a6d63111fa1',
  logAccidentBucketName: 'cc-logaccident',
  logAccidentFolderName: 'img',
  vehicleCareS3BucketName: 'cc-vehiclecare',
  vehicleCareS3FolderName: 'img',
  memberMessageContainerName: 'cc-membermsg',
  signalRApiGatewayUrl: 'https://cchub-uat.carscomm.com.au',
  azureStorageAccountName: 'carscommimagesuat',
  azureBlobUrl: 'https://carscommimagesuat.blob.core.windows.net/'
};
